import {BrowserRouter, Routes, Route} from "react-router-dom"
import MainPage from "./view/pages/Main"
import NotFoundPage from "./view/pages/404";
import ProjectPage from "./view/pages/Projects";
import WrinklyNavBar from "./components/wrinklyNavBar";
import WrinklyKongFooter from "./components/WrinklyKongFooter"
import AboutPage from "./view/pages/About";

function App() {
  return (
    <div className="helpme">
      <BrowserRouter>
        <WrinklyNavBar/>
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/projects" element={<ProjectPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
        <WrinklyKongFooter/>
      </BrowserRouter>
    </div>
  )
}

export default App;
