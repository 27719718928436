
import wrinkly from "../view/images/wrinkly_image.webp"
import "../view/css/Default.css"
import { cur_terminal_ver } from "./Utils"

const WrinklyKongFooter = () => {
    return (
        <div className="wk_footer_container">
            <br/>
            <br/>
            <div className="wk_footer_content">
                <img src={wrinkly} style={{height:'2%', width:'2%'}} alt="WKLogo"></img>
                @ 2024, John Jones  {cur_terminal_ver}
            </div>
        </div>
    )
}

export default WrinklyKongFooter;