import { ReactTerminal } from "react-terminal";
import { get_all_projects, get_project_by_num } from "../services/project_services";

import "../view/css/Terminal.css"
import { 
    get_random_wrinkly, cur_terminal_ver, wk_change_log, 
    terminal_change_log, cur_wrinklykong_ver, about_me_contents, get_john_photo } from "./Utils";

const welcome_message = get_random_wrinkly()

const WKTerminal = () => {

    const list_usage = "Usage: \"list project [ <project_num> | all ]\""

    const db_error_message = (
        <div>
            Failed to grab data from database, wow this guy stinks at programming.<br/>
            Bug me here to fix it: johnwjones1337@gmail.com
        </div>
    )
    const themes = {
        terminal_1: {
            themeBGColor: "#182509",
            themeColor: "#ABFF49",
            themePromptColor: "#52C6FE"
        }
    }

    function sort_results_by_num (results) {
        var sorted = results.data.sort(function(a,b){return a.num - b.num})
        return sorted
    }

    // Spread should follow the format: [25,25,50] and add up to 100
    function format_output (banner, results, number_col=true, spread=[], space_between=true) {
        if ( results === undefined || results.length === 0 ) {
            console.log("Results is undefined")
            return ""
        }

        // get units of stuff
        const result_size = results[0].length                   // Number of columns
        const max_char = get_max_number_of_characters()         // Size of terminal in # of characters
        var usable_area = max_char - 3*(result_size-1) - 1      // Size of usable area (ommits separators and other
                                                                //  spaces etc)
        
        const max_char_size_of_number_unit = (results.length).toString().length
        if ( number_col ) {
            // Calculate max length of objects given
            // Subtract the area needed from the usable_area
            usable_area -= (max_char_size_of_number_unit + 3)
        }
        // Generate the length of each section
        // TODO: Set widths dynamically somehow
        var widths = []
        if ( spread.length != 0 ) {
            if ( spread.length !== result_size ) {
                console.log("Bad spread length")
                return ""
            }
        }
        else {
            spread = Array(result_size).fill(1)
        }
        var tot_spread_size = 0
        var used_space = 0
        for (let x = 0; x < result_size; x++ ) {
            tot_spread_size += spread[x]
        }
        for (let x = 0; x < result_size; x++) {
            const calculated_area = Math.floor(usable_area * (spread[x] / tot_spread_size))
            used_space += calculated_area
            widths.push(calculated_area)
        }
        if (used_space < usable_area) {
            // Add extra space to last one, cuz why not :)))
            widths[result_size] += usable_area - used_space
        }

        // Generate top banner line
        var output = ["\xa0", "\xa0"]
        if ( number_col ) {
            // TODO make dynamic (11 will not work)
            output[1] += "# | "
        }
        for (let x = 0; x < banner.length; x++) {
            const unit_per_object = widths[x]
            output[1] += banner[x]
            output[1] += "\xa0".repeat(unit_per_object - banner[x].length)
            if ( x !== banner.length - 1) {
                output[1] += " | "
            }
        }

        output.push("-".repeat(max_char))

        // repeat for each object in results
        // results = list of lists
        for (let num_results = 0; num_results < results.length; num_results++) {
            // TODO: Add numbers to each project as first row, maybe option in this function???
            var split_up = []
            for (let x = 0; x < results[num_results].length; x++) {
                const current_result_it = results[num_results][x]
                if ( Array.isArray(current_result_it) ) {
                    // TODO: Fix this terribly optimized code lol
                    const unit_per_object = widths[x]
                    var array_to_be_pushed = []
                    for (let y = 0; y < current_result_it.length; y++) {
                        if ( current_result_it[y].length > unit_per_object ) {
                            let regex = new RegExp('.{1,' + unit_per_object + '}', 'g')
                            const res = current_result_it[y].match(regex)
                            for (let z = 0; z < res.length; z++ ) {
                                array_to_be_pushed.push(res[z])
                            }
                        }
                        else {
                            array_to_be_pushed.push(current_result_it[y])
                        }
                    }
                    split_up.push(array_to_be_pushed)
                }
                else {
                    const unit_per_object = widths[x]
                    let regex = new RegExp('.{1,' + unit_per_object + '}', 'g')
                    split_up.push(current_result_it.match(regex))
                }
            }
            // Find max value
            var max = 0
            for (let x = 0; x < split_up.length; x++) {
                if ( split_up[x].length > max ) {
                    max = split_up[x].length
                }
            }
            // Create a beautiful combined array!
            for (let x = 0; x < max; x++) {
                var line = ""
                if ( number_col ) {
                    if ( x === 0 ) {
                        var current_proj_num = num_results.toString()
                        if ( current_proj_num.length < max_char_size_of_number_unit ) {
                            line += "\xa0".repeat(max_char_size_of_number_unit - current_proj_num)
                        }
                        line += num_results.toString()
                        line += " | "
                    }
                    else {
                        line += "\xa0".repeat(max_char_size_of_number_unit+3)
                    }
                }
                for (let y = 0; y < split_up.length; y++) {
                    // Add numbers if requested

                    const unit_per_object = widths[y]
                    if ( x >= split_up[y].length ) {
                        line += "\xa0".repeat(unit_per_object)
                    }
                    else if ( unit_per_object > split_up[y][x].length ) {
                        line += split_up[y][x]
                        line += "\xa0".repeat(unit_per_object - split_up[y][x].length)
                    }
                    else {
                        line += split_up[y][x]
                    }
                    if ( y !== split_up.length - 1 ) {
                        line += " | "
                    }
                }
                output.push("\xa0"+line)
            }
            //  TODO: Make this a div so they seperate nice :)
            if (space_between || num_results === results.length-1) {
                output.push("\xa0")
            }
        }
        return  Array.from(new Array(output.length), (e1, index) => (
                    <div>{output[index]}</div>
                ))
    }

    function get_max_number_of_characters () {
        const width = document.getElementById('terminal_container').clientWidth
        const max_number = Math.floor(width / 12)
        return max_number
    }

    async function show_projects (input) {
        const banner = ["PROJECT NAME", "TAGS", "DESCRIPTION"]
        if ( !input ) {
            return ("Usage: show projects [<project_name> | all]")
        }
        if ( input === "all") {
            const response = await get_all_projects()
            if ( response === null || response.status !== 200 ) {
                return db_error_message
            }
            const sorted_res = sort_results_by_num(response)
            var results_argument = []
            // TODO: Add colored tags (would be based on theme as well, themed-defined tag maps)
            for (let x = 0; x < sorted_res.length; x++) {
                results_argument.push([sorted_res[x].title,
                                       sorted_res[x].labels,
                                       sorted_res[x].desc])
            }

            return format_output(banner, results_argument, true, [3,1,4])
        }
        else {
            // TODO: Can remove this function if not needed
            // function generate_split(input, max_size) {
            //     let regex = new RegExp('.{1,' + max_size + '}', 'g')
            //     return input.match(regex)
            // }

            // Test that the proj_num is actually an integer
            if ( isNaN(Number(input)) ) {
                return "Bad project number provided: '" + input + "', please provide a numeric value"
            }
            const project = await get_project_by_num(input)
            if ( project === null || project.data === null | project.status !== 200 ) {
                return "No result found for project number: " + input
            }
            var output_arr = ["\xa0", (<b>{project.data.title}</b>), project.data.date, "\xa0",
                              (<b>Description:</b>), project.data.desc]            
            if ( project.data.notes !== undefined ) {
                output_arr.push("\xa0")
                output_arr.push(<b>Additional Notes:</b>)
                output_arr.push(project.data.notes+"\n")
            }
            output_arr.push("\xa0")
            output_arr.push(<b>Git Link:</b>)
            output_arr.push("\xa0".repeat(8)+project.data.git_link)
    
            // Add labels if any
            if ( project.data.labels.length > 0 ) {
                output_arr.push((<b>Labels:</b>))
                for (let x = 0; x < project.data.labels.length; x++) {
                    output_arr.push("\xa0".repeat(8)+project.data.labels[x])
                }
            }

            return  Array.from(new Array(output_arr.length), (e1, index) => (
                        <div key={index}>{output_arr[index]}</div>
                    ))
        }
    }

    const commands = {
        // TODO: Brainstorm awesome commands
        commands: () => {
            return (
                <div>
                    <b>Available Commands:</b><br/>
                       -------------------
                    <br/>
                    <b># list project</b><br/>
                    {list_usage}<br/>
                    List details on projects<br/>
                    <br/>
                    <b># commands</b><br/>
                    View available commands<br/><br/>
                    <b># changelog</b><br/>
                    View wrinklykongdotcom changelog<br/>
                    <br/>
                    <b># banner</b><br/>
                    View banner<br/>
                </div>
            )
        },
        list : (input) => {
            if (!input) {
                return list_usage
            }
            // Break string up into many string :)
            var full_cmd_array = input.split(" ")
            var cmd = ""
            var target = ""
            if ( full_cmd_array.length === 0 ) {
                return "Unknown error occured"
            }
            else if (full_cmd_array.length === 1) {
                return list_usage
            }
            else if (full_cmd_array.length === 2) {
                cmd =       full_cmd_array[0]
                target =    full_cmd_array[1]
                if ( cmd === "project" ) {
                    return show_projects(target)
                }
                else {
                    return "Invalid selection: '"+target+"'. "+list_usage
                }
            }
            else {
                return list_usage
            }
        },
        changelog: (input) => {
            var term_chg = format_output(["Version", "Change"], terminal_change_log, false, [1,3], false)
            var wk_chg = format_output(["Version", "Change"], wk_change_log, false, [1,3], false)
            return (
                <div key="key">
                    <b>Terminal version: {cur_terminal_ver}</b><br/>
                    {term_chg}
                    <b>Wrinkly kong version: {cur_wrinklykong_ver}</b>
                    {wk_chg}
                </div>
            )
        },
        banner: () => {
            return get_random_wrinkly()
        },
        aboutme: () => {
            return (
                <div>
                    {get_john_photo()}
                    <b>About Me</b>
                    {about_me_contents}
                    <i>To see information on my projects, run `list project all`</i>
                </div>
            );
        },
        secret: () => {
            return "Hey! How'd you find this?"
        }
    };

    // TODO: add tab completions
    // const handleKeyPress = (event) => {
    //     if(event.key === "tab"){
    //         console.log("tabbed")
    //     }
    // }

    return (
        <div className="terminal_container" id="terminal_container">
            <ReactTerminal
                prompt="#"
                welcomeMessage={welcome_message}
                showControlBar={false}
                showControlButtons={false}
                themes={themes}
                theme={"terminal_1"}
                commands={commands}
                errorMessage="Unknown command. Run `commands` to view available commands"
            />
        </div>
    )
}

export default WKTerminal; 