const wrinkly_ascii = [
    [
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@\xa0@@.\xa0",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0&%@%@@\xa0\xa0\xa0\xa0\xa0\xa0.@@@%&/&",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@\xa0@@\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0#@((",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0@&@@\xa0\xa0\xa0\xa0\xa0(\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0#@&\xa0\xa0\xa0&@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0#@@\xa0\xa0@%\xa0\xa0\xa0\xa0.@/\xa0@@\xa0\xa0\xa0\xa0\xa0\xa0*@@@&",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0%@\xa0@*\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0&@",
        "\xa0\xa0\xa0@,\xa0\xa0\xa0#@,(&@@@@@@@@@@@@@@@@@@@@@@@@/",
        "\xa0\xa0\xa0@\xa0\xa0\xa0\xa0\xa0@#@\xa0\xa0\xa0@\xa0\xa0\xa0\xa0,@@@@@@\xa0\xa0@\xa0\xa0\xa0\xa0\xa0@(@",
        "\xa0\xa0\xa0\xa0\xa0@@@@@\xa0/@@@@\xa0\xa0@@\xa0@@@@@@@\xa0*@\xa0@@\xa0\xa0\xa0@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@\xa0\xa0.%%/,.\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@@\xa0\xa0\xa0\xa0\xa0@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0/@\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0*@\xa0\xa0@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@#\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@\xa0@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@@@\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@\xa0@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@@\xa0\xa0%@@/\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@#&@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0@\xa0@\xa0\xa0\xa0\xa0&@@@@@@@@@@@@@@@@@\xa0(@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0.@@.\xa0\xa0\xa0\xa0\xa0\xa0%@@(\xa0\xa0\xa0\xa0\xa0\xa0@@",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0,/#&@@@@/"
    ],
    [
        "\xa0\xa0▄\xa0▄\xa0\xa0\xa0█▄▄▄▄\xa0▄█\xa0\xa0\xa0\xa0▄\xa0\xa0\xa0█\xa0\xa0█▀\xa0█\xa0\xa0\xa0\xa0▀▄\xa0\xa0\xa0\xa0▄\xa0█\xa0\xa0█▀\xa0████▄\xa0\xa0\xa0\xa0▄\xa0\xa0\xa0\xa0\xa0▄▀\xa0\xa0",
        "\xa0█\xa0\xa0\xa0█\xa0\xa0█\xa0\xa0▄▀\xa0██\xa0\xa0\xa0\xa0\xa0█\xa0\xa0█▄█\xa0\xa0\xa0█\xa0\xa0\xa0\xa0\xa0\xa0█\xa0\xa0█\xa0\xa0█▄█\xa0\xa0\xa0█\xa0\xa0\xa0█\xa0\xa0\xa0\xa0\xa0█\xa0\xa0▄▀\xa0\xa0\xa0\xa0",
        "█\xa0▄\xa0\xa0\xa0█\xa0█▀▀▌\xa0\xa0██\xa0██\xa0\xa0\xa0█\xa0█▀▄\xa0\xa0\xa0█\xa0\xa0\xa0\xa0\xa0\xa0\xa0▀█\xa0\xa0\xa0█▀▄\xa0\xa0\xa0█\xa0\xa0\xa0█\xa0██\xa0\xa0\xa0█\xa0█\xa0▀▄\xa0\xa0",
        "█\xa0\xa0█\xa0\xa0█\xa0█\xa0\xa0█\xa0\xa0▐█\xa0█\xa0█\xa0\xa0█\xa0█\xa0\xa0█\xa0\xa0███▄\xa0\xa0\xa0\xa0█\xa0\xa0\xa0\xa0█\xa0\xa0█\xa0\xa0▀████\xa0█\xa0█\xa0\xa0█\xa0█\xa0\xa0\xa0█\xa0",
        "\xa0█\xa0█\xa0█\xa0\xa0\xa0\xa0█\xa0\xa0\xa0\xa0▐\xa0█\xa0\xa0█\xa0█\xa0\xa0\xa0█\xa0\xa0\xa0\xa0\xa0\xa0\xa0▀\xa0▄▀\xa0\xa0\xa0\xa0\xa0\xa0\xa0█\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█\xa0\xa0█\xa0█\xa0\xa0███\xa0\xa0",
        "\xa0\xa0▀\xa0▀\xa0\xa0\xa0\xa0▀\xa0\xa0\xa0\xa0\xa0\xa0\xa0█\xa0\xa0\xa0██\xa0\xa0▀\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0▀\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
    ]
]

export const cur_terminal_ver = "v1.0.0"
export const cur_wrinklykong_ver = "v1.0.0"
export const wk_change_log = [
    ["0.1.0", "First deployment!"],
    ["1.0.0", "Added Terminal to home page"]
]

export const terminal_change_log = [
    ["1.0.0", "Initial release"],
]

export function get_random_wrinkly() {
    const wrinkly_chosen = wrinkly_ascii[Math.floor(Math.random()*wrinkly_ascii.length)]
    return  (
        <div>            
            {Array.from(new Array(wrinkly_chosen.length), (e1, index) => (
                <div key={index}>{wrinkly_chosen[index]}</div>
            ))}
            <div>
                WK VERSION: {cur_wrinklykong_ver} | WK_DOT_COM VERSION: {cur_wrinklykong_ver}
            </div>
            <div>
                Run "commands" to view available commands
            </div>
        </div>
        )
}

export const about_me_contents = (
    <p>
        My name is John Jones and I am a Cyber Engineering at iCR, inc. I am a Computer Science 
        graduate from the University of Texas at San Antonio with interests in embedded systems security, 
        wireless systems, green 5G, and network security.
        <br/><br/>
        I am experienced in microcontroller programming, network programming, reverse engineering, and
        electronics.
    </p>
)

const john_photo = [
"████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███",
"█\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████████████████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0█",
"\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0███████████████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███████\xa0███████████\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0██████\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0████████\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0█████████\xa0\xa0\xa0\xa0\xa0█\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0█████████\xa0\xa0\xa0\xa0████████████\xa0\xa0██████████\xa0\xa0\xa0\xa0\xa0█\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0█████████\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0██████████\xa0\xa0\xa0\xa0\xa0█\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0███████\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0████████████████████████\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0██\xa0██\xa0████████\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███████████████████████████████\xa0\xa0██████\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0███████████\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0██\xa0\xa0█████████\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0\xa0\xa0█████████████████████\xa0\xa0███\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0█████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0███████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███████\xa0\xa0\xa0\xa0███\xa0\xa0█\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████████████████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0█████\xa0\xa0█████\xa0\xa0\xa0\xa0████\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0██\xa0\xa0████\xa0██████\xa0███\xa0███\xa0██\xa0\xa0███\xa0██\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███████\xa0\xa0\xa0████\xa0\xa0\xa0\xa0█████\xa0\xa0\xa0█████\xa0\xa0███████\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0███\xa0\xa0\xa0\xa0████\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
]

export function get_john_photo() {
    return (
        <div>
            {Array.from(new Array(john_photo.length), (e1, index) => (
                <div key={index}>{john_photo[index]}</div>
            ))}
        </div>
    )
}