import { TerminalContextProvider } from "react-terminal"
import WKTerminal from "../../components/WKTerminal";

import "../css/Default.css"

const MainPage =() => {
    return(
        <div className="test">
            <TerminalContextProvider>
                <WKTerminal/>
            </TerminalContextProvider>
        </div>
    );
}

export default MainPage;