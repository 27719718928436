import "../css/About.css"
import JohnPhoto from "../images/profpic.jpg"
import Meridia from "../images/meridia.jpg"
import Ajani from "../images/ajani.jpg"
import gaylovers from "../images/gaylovers.jpg"

import { about_me_contents } from "../../components/Utils";

const AboutPage =() => {
    return(
        <div>
            <h1>
                About Me!
            </h1>
            <div className="block">
                <div className="paragraph">
                    <img src={JohnPhoto} id="johnphoto" align="left" alt="john jones"/>
                    <div>
                        {about_me_contents}
                        <br/>
                        <div className="cat_contents">
                            <p>
                                I HAVE TWO BEAUTIFUL CATS THEIR NAMES ARE MERIDIA AND AJANI.<br/>
                                THIS ONE IS MERIDIA:<br/>
                                <img src={Meridia} id="johnphoto" alt="aweome cat"/><br/>
                                THIS ONE IS AJANI:<br/>
                                <img src={Ajani} id="johnphoto" alt="awesomer cat"/><br/>
                                THEY KEEP ME UP LATE AT NIGHT AND WAKE ME UP EARLY IN THE MORNING.<br/>
                                I WAKE UP WITH RANDOM SCRATCHES.<br/>
                                IF I MISS A DEADLINE, BLAME THEM.<br/><br/>
                                THEY LIKE TO BEAT EACH OTHER UP AND STEAL EACH OTHERS FOOD.<br/>
                                HERE THEY ARE BEING GAY:<br/>
                                <img src={gaylovers} id="gaycats" alt="gaycats"/><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;