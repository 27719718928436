import "../css/404.css"

const NotFoundPage =() => {
    return(
        <div className="page_not_found">
            <p>Not found!</p>
        </div>
    );
}

export default NotFoundPage;