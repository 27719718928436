import loading from "../view/images/loading.gif"

import "../view/css/Loading.css"


const Loading = () => {
    return (
        <div className="loading">
            <img className="loading_gif" src={loading} alt="loading gif"></img>
            <h1>Loading...</h1>
            <img className="loading_gif" src={loading} alt="loading gif"></img>
        </div>
    )
}

export default Loading;